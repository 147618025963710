<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editPromotion"
    :normalizer="normalizer"
    :options="{
      properties: {
        name: '',
        productGroups: [],
        customerGroups: [],
        discount: null,
        minPrice: { currency: 'CHF', amount: 0 },
        availableMonday: false,
        startMonday: '00:00',
        endMonday: '23:59',
        availableTuesday: false,
        startTuesday: '00:00',
        endTuesday: '23:59',
        availableWednesday: false,
        startWednesday: '00:00',
        endWednesday: '23:59',
        availableThursday: false,
        startThursday: '00:00',
        endThursday: '23:59',
        availableFriday: false,
        startFriday: '00:00',
        endFriday: '23:59',
        availableSaturday: false,
        startSaturday: '00:00',
        endSaturday: '23:59',
        availableSunday: false,
        startSunday: '00:00',
        endSunday: '23:59',
        oncePerDay: false,
      },
      postUri: '/promotions',
      label: $t('promotion.label', 1),
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
    <fancy-checkbox
      v-model="properties.oncePerDay"
      :label="$t('promotion.oncePerDay')"
    />
    <multiselect
      v-model="properties.productGroups"
      :options="async (q) => loadProductGroups(q, properties.productGroups)"
      :filter-results="false"
      :error="errors.productGroups"
      :form-label="$t('productGroup.label', 2)"
      :loading="loadingProductGroups"
      mode="tags"
      searchable
    />
    <multiselect
      v-model="properties.customerGroups"
      :options="async (q) => loadCustomerGroups(q, properties.customerGroups)"
      :filter-results="false"
      :error="errors.customerGroups"
      :form-label="$t('customerGroup.label', 2)"
      :loading="loadingCustomerGroups"
      mode="tags"
      searchable
    />
    <multiselect
      v-model="properties.discount"
      :options="async (q) =>loadDiscounts(q, properties.discount)"
      :filter-results="false"
      :error="errors.discount"
      :form-label="$t('discount.label', 1)"
      :loading="loadingDiscounts"
      mode="single"
      searchable
      required
    />

    <price-form v-model="properties.minPrice" :label="$t('promotion.minPrice.label')" :error="errors.minPrice" />

    <availability-form
      v-for="day in weekdays"
      :key="`availability${day}`"
      v-model:available-day="properties[`available${day}`]"
      v-model:available-start="properties[`start${day}`]"
      v-model:available-end="properties[`end${day}`]"
      :day="day.toLowerCase()"
    />
  </modal-form>
</template>

<script>
import { timeStringToISOString } from '@/utils/date-utils';
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import PriceForm from '@/components/shared/forms/PriceForm.vue';
import AvailabilityForm from '@/components/entities/promotion/AvailabilityForm.vue';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';

export default {
  name: 'EditPromotionModal',
  components: {
    AvailabilityForm,
    ModalForm,
    FancyInput,
    Multiselect,
    PriceForm,
    FancyCheckbox,
  },
  setup() {
    const {
      loading: loadingProductGroups,
      loadOptions: loadProductGroups,
    } = useLoadOptions('/product_groups', 'name');
    const {
      loading: loadingCustomerGroups,
      loadOptions: loadCustomerGroups,
    } = useLoadOptions('/customer_groups', 'name');
    const {
      loading: loadingDiscounts,
      loadOptions: loadDiscounts,
    } = useLoadOptions('/discounts', 'name');
    const weekdays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    const normalizer = (entity) => ({
      ...entity,
      minPrice: entity?.minPrice != null ? {
        ...entity.minPrice,
        '@id': undefined,
        id: entity.minPrice['@id'],
      } : undefined,
      startMonday: timeStringToISOString(entity.startMonday),
      startTuesday: timeStringToISOString(entity.startTuesday),
      startWednesday: timeStringToISOString(entity.startWednesday),
      startThursday: timeStringToISOString(entity.startThursday),
      startFriday: timeStringToISOString(entity.startFriday),
      startSaturday: timeStringToISOString(entity.startSaturday),
      startSunday: timeStringToISOString(entity.startSunday),
      endMonday: timeStringToISOString(entity.endMonday),
      endTuesday: timeStringToISOString(entity.endTuesday),
      endWednesday: timeStringToISOString(entity.endWednesday),
      endThursday: timeStringToISOString(entity.endThursday),
      endFriday: timeStringToISOString(entity.endFriday),
      endSaturday: timeStringToISOString(entity.endSaturday),
      endSunday: timeStringToISOString(entity.endSunday),
    });

    return {
      loadingProductGroups,
      loadProductGroups,
      loadingCustomerGroups,
      loadCustomerGroups,
      loadingDiscounts,
      loadDiscounts,
      weekdays,
      normalizer,
    };
  },
};
</script>
