<template>
  <div>
    <spinner v-if="loading" color="black" />
    <template v-else>
      <entity-card
        :entity="entity"
        :fields="fields"
        :title="entity.name"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              v-t="'general.action.delete'"
              class="small white mr-2"
              @click="$store.dispatch('modals/openModal', { modal: 'deletePromotion', entity })"
            />
            <button
              v-t="'general.action.edit'"
              class="small"
              @click="$store.dispatch('modals/openModal', { modal: 'editPromotion', entity: normalizedEntity })"
            />
          </div>
        </template>

        <template #productGroups>
          <span v-if="!entity.productGroups.length" v-t="'general.none'" />
          <admin-entity-link
            v-for="group in entity.productGroups"
            v-else
            :key="group['@id']"
            class="badge mr-2"
            :to="group"
          >
            {{ group.name }}
          </admin-entity-link>
        </template>
        <template #customerGroups>
          <span v-if="!entity.customerGroups.length" v-t="'general.none'" />
          <admin-entity-link
            v-for="group in entity.customerGroups"
            v-else
            :key="group['@id']"
            class="badge mr-2"
            :to="group"
          >
            {{ group.name }}
          </admin-entity-link>
        </template>
        <template #discount>
          <admin-entity-link class="badge" :to="entity.discount">
            {{ entity.discount.name }}
          </admin-entity-link>
        </template>
        <template #availabilities>
          <div class="-mx-8">
            <table>
              <thead>
                <tr>
                  <th v-t="'promotion.availability.availableOn'" />
                  <th v-t="'promotion.availability.start'" />
                  <th v-t="'promotion.availability.end'" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="day in weekdays"
                  :key="day"
                >
                  <td :class="{ 'font-bold': entity[`available${day}`] }" v-text="$t(`general.datetime.${day.toLowerCase()}`)" />
                  <td>
                    <template v-if="entity[`available${day}`]">
                      {{ entity[`start${day}`] ? getTimeString(entity[`start${day}`]) : '00:00' }}
                      {{ $t('general.datetime.clock') }}
                    </template>
                    <template v-else>
                      —
                    </template>
                  </td>
                  <td>
                    <template v-if="entity[`available${day}`]">
                      {{ entity[`end${day}`] ? getTimeString(entity[`end${day}`]) : '23:59' }}
                      {{ $t('general.datetime.clock') }}
                    </template>
                    <template v-else>
                      —
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </entity-card>

      <edit-promotion-modal @update="loadData" />
      <delete-modal type="deletePromotion" entity-translation-key="promotion" @update="$router.push({ name: 'promotions' })" />
    </template>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import axios from '@/services/axios';
import formatPrice from '@/utils/formatPrice';
import { getTimeString } from '@/utils/date-utils';
import EntityCard from '@/components/shared/EntityCard.vue';
import Spinner from '@/components/utils/spinner.vue';
import EditPromotionModal from '@/components/entities/promotion/EditPromotionModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  name: 'PromotionDetailPage',
  components: {
    DeleteModal,
    EditPromotionModal,
    Spinner,
    EntityCard,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const loading = ref(true);
    const entity = ref(null);
    const normalizedEntity = computed(() => ({
      ...entity.value,
      productGroups: entity.value?.productGroups?.map((g) => g['@id']),
      customerGroups: entity.value?.customerGroups?.map((g) => g['@id']),
      discount: entity.value?.discount?.['@id'],
      startMonday: getTimeString(entity.value?.startMonday),
      startTuesday: getTimeString(entity.value?.startTuesday),
      startWednesday: getTimeString(entity.value?.startWednesday),
      startThursday: getTimeString(entity.value?.startThursday),
      startFriday: getTimeString(entity.value?.startFriday),
      startSaturday: getTimeString(entity.value?.startSaturday),
      startSunday: getTimeString(entity.value?.startSunday),
      endMonday: getTimeString(entity.value?.endMonday),
      endTuesday: getTimeString(entity.value?.endTuesday),
      endWednesday: getTimeString(entity.value?.endWednesday),
      endThursday: getTimeString(entity.value?.endThursday),
      endFriday: getTimeString(entity.value?.endFriday),
      endSaturday: getTimeString(entity.value?.endSaturday),
      endSunday: getTimeString(entity.value?.endSunday),
    }));
    const fields = ref([
      { key: 'productGroups', label: t('productGroup.label', 2) },
      { key: 'customerGroups', label: t('customerGroup.label', 2) },
      { key: 'discount', label: t('discount.label') },
      { key: 'minPrice', label: t('promotion.minPrice.label'), formatter: formatPrice },
      { key: 'oncePerDay', label: t('promotion.oncePerDay') },
      { key: 'availabilities', label: t('promotion.availability.label', 2), colspan: true },
    ]);
    const weekdays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    const loadData = async () => {
      loading.value = true;

      try {
        const { data: promotion } = await axios.get(`/promotions/${route.params.id}`);
        entity.value = promotion;
      } catch (err) {
        console.error(err, err.message, err.response);
      }

      loading.value = false;
    };

    loadData();

    return {
      route,
      loading,
      entity,
      normalizedEntity,
      fields,
      weekdays,
      loadData,
      getTimeString,
    };
  },
};
</script>

<style lang="scss" scoped>
th:first-of-type,
td:first-of-type {
  @apply pl-8;
}

th:last-of-type,
td:last-of-type {
  @apply pr-8;
}
</style>
