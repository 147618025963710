<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('promotion.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editPromotion' , entity: {
          productGroups: [],
          customerGroups: [],
          discount: null,
          minPrice: { currency: 'CHF', amount: 0 },
          availableMonday: false,
          startMonday: '00:00',
          endMonday: '23:59',
          availableTuesday: false,
          startTuesday: '00:00',
          endTuesday: '23:59',
          availableWednesday: false,
          startWednesday: '00:00',
          endWednesday: '23:59',
          availableThursday: false,
          startThursday: '00:00',
          endThursday: '23:59',
          availableFriday: false,
          startFriday: '00:00',
          endFriday: '23:59',
          availableSaturday: false,
          startSaturday: '00:00',
          endSaturday: '23:59',
          availableSunday: false,
          startSunday: '00:00',
          endSunday: '23:59',
          oncePerDay: false,
        } })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editPromotion', entity: $event })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deletePromotion', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    />

    <edit-promotion-modal @update="loadEntities" />
    <delete-modal type="deletePromotion" entity-translation-key="promotion" @update="loadEntities" />
  </div>
</template>

<script>
import useLoadEntities from '@/hooks/api/loadEntities';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditPromotionModal from '@/components/entities/promotion/EditPromotionModal.vue';

export default {
  name: 'PromotionIndexPage',
  components: {
    EditPromotionModal,
    EntityTable,
    DeleteModal,
  },
  setup() {
    const { promotion: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/promotions', { sort: 'name' });

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    };
  },
};
</script>
