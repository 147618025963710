<template>
  <div>
    <fancy-checkbox
      v-model="availableDayModel"
      :label="`${$t('promotion.availability.availableOn')} ${$t(`general.datetime.${day}`)}`"
    />
    <template v-if="availableDayModel">
      <fancy-input v-model="availableStartModel" :label="$t('promotion.availability.start')" type="time" />
      <fancy-input v-model="availableEndModel" :label="$t('promotion.availability.end')" type="time" />
    </template>
  </div>
</template>

<script>
import { useModel } from '@/hooks/model';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';

export default {
  name: 'AvailabilityForm',
  components: {
    FancyInput,
    FancyCheckbox,
  },
  props: {
    day: { type: String, default: 'monday' },
    availableDay: { type: Boolean, default: false },
    availableStart: { type: String, default: null },
    availableEnd: { type: String, default: null },
  },
  setup(props, { emit }) {
    const availableDayModel = useModel(props, emit, 'availableDay');
    const availableStartModel = useModel(props, emit, 'availableStart');
    const availableEndModel = useModel(props, emit, 'availableEnd');

    return {
      availableDayModel,
      availableStartModel,
      availableEndModel,
    };
  },
};
</script>
